import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

@HostListener('window:scroll', ['$event'])

export class HomeComponent implements OnInit {

  constructor(private router: Router) { }

  hValues:any = ['Complete', 'Dynamic','Integrated', 'Accessible']; 
  hval = 'Complete';
  

  ngOnInit(): void {
    
    setInterval(() => {
      this.hval = this.hValues[this.index];
      if(this.index === this.hValues.length-1) this.index=0;
      else this.index++; 
    }, 1500)
    
    window.addEventListener('scroll', this.scroll, true);
    
  }

  

 //timer = null; // Will hold a reference to the timer
 index = 0;    // Keeps track of which array element to show next


// You'll want to stop the interval when you're done iterating the array
// so you need to set u a reference to it to refer to later.

  scroll = (): void => {

    let scrollHeigth;
 
    if(window.innerWidth < 350){
     scrollHeigth = 150;
    }else if(window.innerWidth < 500 && window.innerWidth > 350){
     scrollHeigth = 250;
    }else if(window.innerWidth < 700 && window.innerWidth > 500){
     scrollHeigth = 350;
    }else if(window.innerWidth < 1000 && window.innerWidth > 700){
     scrollHeigth = 500;
    }else{
      scrollHeigth = 650;
    }
 
     if(window.scrollY >= scrollHeigth){
      // document.getElementById('n2')!.style.display = 'none';
       document.body.style.setProperty('--navbar-scroll', "#242424");
       document.body.style.setProperty('--navbar-scroll-text', "black");
       document.body.style.setProperty('--navbar-scroll-shadow', "0px 10px 10px 0px rgba(0, 0, 0, 0.20)");
     }else if(window.scrollY < scrollHeigth){
      // document.getElementById('n2')!.style.display = 'block';
      //console.log(document.getElementById('n2'));
      //console.log(document.getElementById('pcd'));
       document.body.style.setProperty('--navbar-scroll', "transparent");
       document.body.style.setProperty('--navbar-scroll-text', "white");
       document.body.style.setProperty('--navbar-scroll-shadow', "none");
     }
   }



  contactnav() {
    this.router.navigate(['contact']);
    window.scrollTo(0, 0);
  }
  
  homenav() {
    this.router.navigate(['home']);
    window.scrollTo(0, 0);
  }
  
  termsnav() {
    this.router.navigate(['terms']);
    window.scrollTo(0, 0);
  }

  legalnav() {
    this.router.navigate(['legal']);
    window.scrollTo(0, 0);
  }

  demonav() {
    this.router.navigate(['demo'])
  // .then(() => {
  //   window.location.reload();
  // });
  }
}
