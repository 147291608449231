<div class="" style="font-family: 'Poppins', sans-serif;background-color: #fff;"> <!--container-fluid-->
    <!-- First Div -->
    <div class="firstDiv" style="background-image: url('assets/restaurant-interior.webp');"> <!--container mt-5-->
        <nav class="navbar navbar-expand-sm fixed-top" data-bs-theme="dark">
            <div class="container-fluid nc">
              <a class="navbar-brand" (click)="homenav()">
                <img  src="assets/Header_Logo.webp" class="n1-1 img-fluid" >
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <!-- <span class="navbar-toggler-icon text-white" style="color: white;"></span> -->
                <img  src="assets/menu.webp" class="img-fluid" style="width: 21px;height: 21px;" >
              </button>
              <div class="collapse navbar-collapse position-relative" id="navbarSupportedContent">
                <ul class="navbar-nav me-2 mb-2 mb-lg-0 text-center w-100  d-flex justify-content-center "> <!--me-auto bg-primary-->
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page" (click)="homenav()" style="color: #0085FF;text-decoration: underline;">Home</a> <!--id="n2"-->
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="contactnav()">Contact Us</a>
                  </li>
                </ul>
                <div class="d-flex justify-content-center">
                    <div (click)="demonav()" class="wdBtn">Watch Demo</div>
                </div>
              </div>
            </div>
        </nav>
        <div class="fd-1"> </div>
        <div class="fdnm">
            <!-- <p class="fd-2">
                The
                <span *ngIf="hval=='Complete'" id="pcd" style="color: #0085FF;letter-spacing: 1.5px;">{{hval}}</span> 
                <span *ngIf="hval=='Dynamic'" id="pcd" style="color: #0085FF;letter-spacing: 4px;">{{hval}}</span> 
                <span *ngIf="hval=='Integrated'" id="pcd" style="color: #0085FF;">{{hval}}</span> 
                <span *ngIf="hval=='Accessible'" id="pcd" style="color: #0085FF;">{{hval}}</span> 
                Hospitality App.  
            </p> -->
            <!-- <div class="d-flex justify-content-center fd-2" style="color: #FFF;">
                The
                <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                Hospitality App.  
            </div> -->
            <div class="d-flex flex-row fd-2" style="color: #FFF;">
                The
                <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                Hospitality App.  
            </div>
            <!-- <p class="fd-2">
                The <br>
                <span id="pcd" style="color: #0085FF;">{{hval}}</span> 
                Hospitality App.  
            </p> -->
            <div class="fd-33">
                <div class="fd-3">
                    <span class="fd-3-1">Manage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Engage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Analyze</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Maximize</span>
                    <span class="fd-3-3"> </span>
                </div>
                <span class="fd-3-4">FRI was created to solve the hospitality industry’s main problem: Variability. </span>
            </div>
        </div>
        <div class="fdt">
            <div class="fdt-2">
                The
                <div class="d-flex justify-content-center mt-3">
                    <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                    <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                    <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                    <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                    Hospitality App.  
                </div>
            </div>
            <!-- <div class="fdt-2">
                The
                <p class="mt-3">
                    <span id="pcd" style="color: #0085FF;">{{hval}}</span> 
                Hospitality App.  
                </p>
            </div> -->
            <!-- <div class="d-flex justify-content-center fdt-2" style="color: #FFF;">
                The <br>
                <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                Hospitality App.  
            </div> -->
            <div class="fd-33">
                <div class="fd-3">
                    <span class="fd-3-1">Manage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Engage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Analyze</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Maximize</span>
                    <!-- <hr class="fd-3-3">  -->
                </div>
                <span class="fd-3-3-3"></span>
                <span class="fd-3-4">FRI was created to solve the hospitality industry’s main problem: Variability. </span>
            </div>
        </div>
        <div class="fdm">
            <!-- <p class="fdm-2">
                The 
                <span id="pcd" style="color: #0085FF;">{{hval}}</span> 
                Hospitality App.  
            </p> -->
            <!-- <p class="fdm-2">
                The 
                <span id="pcd" style="color: #0085FF;">{{hval}}</span> 
                Hospitality App.  
            </p> -->
            <!-- <div class="d-flex justify-content-center fdm-2" style="color: #FFF;">
                The
                <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                Hospitality App.  
            </div> -->
            <div class="fdm-2">
                <div class="d-flex justify-content-center mt-3">
                    The
                    <p *ngIf="hval=='Complete'" id="pcd" style="letter-spacing: 1.5px;">{{hval}}</p> 
                    <p *ngIf="hval=='Dynamic'" id="pcd" style="letter-spacing: 4px;">{{hval}}</p> 
                    <p *ngIf="hval=='Integrated'" id="pcd" style="">{{hval}}</p> 
                    <p *ngIf="hval=='Accessible'" id="pcd" style="">{{hval}}</p> 
                </div>
                Hospitality App.  
            </div>
            <div class="fd-33">
                <div class="fd-3">
                    <span class="fd-3-1">Manage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Engage</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Analyze</span>
                    <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid" >
                    <span class="fd-3-1">Maximize</span>
                    <!-- <hr class="fd-3-3">  -->
                </div>
                <span class="fd-3-3-3"></span>
                <span class="fd-3-4">FRI was created to solve the hospitality industry’s main problem: Variability. </span>
            </div>
        </div>
        <div class="fd-4">
            <a >
                <img  src="assets/Frame1000005889.webp" class="fd-4-1 img-fluid" >
            </a>
            <p class="fd-4-2 mt-3">Scroll to Discover</p>
        </div>
    </div>
    <!-- OUR SERVICES -->
    <div class="OurServices">
        <div class="os1">
            <span class="os1-1"></span>
            <span class="os1-2">Our Services</span>
            <span class="os1-1"></span>
        </div>
        <h1 class="os11 text-center pb-4">Tailored solutions for Your Every Need</h1>
        <div class="row db gx-4 gx-sm-5 gx-lg-4" style="background-image: url('assets/border.webp'), url('assets/border1.webp');">
            <div class="col-sm-6 col-md-6 col-lg-3"> <!-- db mb-3 mb-sm-0 -->
              <div class="card h-100 "> <!--pt-3 dbc1-->
                <div class="card-body">
                    <img  src="assets/Group1000001638.webp" class="os2" >
                    <h5 class="os3 card-title">Ready-to-use</h5>
                    <p class="os4 card-text" >Install FRI on your tablet, our agents will create your account, and within 24 hours you’re ready for Full Restaurant Integration.</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="card h-100 "> <!--pt-3 pb-2 dbc2-->
                  <div class="card-body">
                      <img  src="assets/Group1000002399.webp" class="os2" >
                      <h5 class="os3 card-title">Real Time Inventory</h5>
                      <p class="os4 card-text" >Know your inventory at all times with simplified, live tracking.  </p>
                  </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
              <div class="card h-100 "> <!--pt-3 pb-2 dbc1-->
                <div class="card-body">
                    <img  src="assets/Group1000001639.webp" class="os2" >
                    <h5 class="os3 card-title">No Subscription Fee</h5>
                    <p class="os4 card-text" >Put your profits where you need them the most, not in monthly fees and contracts.</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3"> <!--db1-->
              <div class="card h-100"> <!--pt-3 pb-2 dbc2-->
                <div class="card-body">
                    <img  src="assets/Group10000016391.webp" class="os2" >
                    <h5 class="os3 card-title">Online Ordering</h5>
                    <p class="os4 card-text" >Connect to your customers with free, integrated online ordering for pickup and pre-orders. </p>
                </div>
              </div>
            </div>
        </div>
    </div>
    <!-- WorkingBar -->
    <div class="WorkingBar" style="background-image: url('assets/working-bar.webp');"> <!--container-->
        <img  src="assets/commas.webp" class="WorkingBar1 img-fluid" >
        <p class="WorkingBar2 mt-3 mt-md-4 mt-lg-5">SERVE MORE, SAVE MORE</p>
        <p class="WorkingBar3">By integrating management, back-of-house, front-of-house, and the consumer into one concise Platform, FRI offers restaurants the ability to organize, streamline, and increase efficiency.  </p>
    </div>
    <!-- RETHINK HOW YOU RESTAURANT -->
    <div class="rethink">
        <div class="os1">
            <span class="os1-1"></span>
            <span class="os1-2">Our Features</span>
            <span class="os1-1"></span>
        </div>
        <h1 class="text-center rt1 pb-1">RETHINK HOW YOU RESTAURANT</h1>
        <div class="rt2">
            <div class="card h-100 pt-3 pb-2">
                <div class="row">
                    <div class="col-sm-6">
                        <img  src="assets/Dashboard.webp" class="img-fluid" >
                    </div>
                    <div class="col-sm-6">
                        <div class="card-body">
                            <p class="card-title rethink1">Manager</p>
                            <div class="rethink2 d-flex"> <!--rethink2-->
                                <span class="rethink21">Operations at a Glance</span>
                                <span class="rethink2-1"> </span>
                            </div>
                            <p class="card-title rethink3">FRI was created to solve the hospitality industry’s main problem: Variability.</p>
                            <ul class="rethinkUL">
                                <li>Manage your restaurant with fewer clicks from one screen</li>
                                <li>Integrated inventory for more accurate purchasing</li>
                                <li>Intuitive labor & sales analysis</li>
                                <li>Well-organized settings screens for easy configuration</li>
                                <li>Mobilize your restaurant with only a wifi conncetion</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card h-100 pt-3 pb-2">
                <div class="row cr">
                    <div class="col-sm-6">
                        <div class="card-body">
                            <p class="card-title rethink1">Service</p>
                            <div class="rethink2 d-flex">
                                <span class="rethink21">A Modern Interface for Modern Devices</span>
                                <span class="rethink2-2"> </span>
                            </div>
                            <p class="card-title rethink3">A POS system that serves your servers.</p>
                            <ul class="rethinkUL">
                                <li>Place orders quickly on an intuitive POS</li>
                                <li>See your inventory without talking to the kitchen</li>
                                <li>Freely edit items in the cart without having to start over</li>
                                <li>Quick access to item descriptions & allergies</li>
                                <li>Modification Sets for easy order customization</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <img  src="assets/ServicePOS.webp" class="img-fluid" >
                    </div>
                </div>
            </div>
            <div class="card h-100 pt-3 pb-2">
                <div class="row">
                    <div class="col-sm-6">
                        <img  src="assets/Kitchen.webp" class="img-fluid" >
                    </div>
                    <div class="col-sm-6">
                        <div class="card-body">
                            <p class="card-title rethink1">Kitchen</p>
                            <div class="rethink2 d-flex">
                                <span class="rethink21">Introducing Your Culinary Co-Pilot</span>
                                <span class="rethink2-3"> </span>
                            </div>
                            <p class="card-title rethink3">Built-in organization that all kitchens crave augmented by ai to streamline your workflow.</p>
                            <ul class="rethinkUL">
                                <li>Proprietary Kitchen Expediting A.I. that works behind the scenes</li>
                                <li>Automatically group like items for smoother kitchen operations</li>
                                <li>Dynamic color coordination for easily identified bottlenecks</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card h-100 pt-3 pb-2">
                <div class="row cr">
                    <div class="col-sm-6">
                        <div class="card-body">
                            <p class="card-title rethink1">Customer</p>
                            <div class="rethink2 d-flex">
                                <span class="rethink21">Designed by Industry professionals</span>
                                <span class="rethink2-4"> </span>
                            </div>
                            <p class="card-title rethink3">Whether in a stadium or restaurant, FRI enhances your dining experience.</p>
                            <ul class="rethinkUL">
                                <li>Order from your phone, no app required</li>
                                <li>Tableside QR code menu access and ordering</li>
                                <li>Mobile payments for quicker checkout</li>
                                <li>Checkout includes Apple and Google Pay</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <img  src="assets/Frame1000005991.webp" class="img-fluid" >
                    </div>
                </div>
            </div>
        </div>
    </div>  
    <!-- Testimonials -->
    <!-- <div class="testimonials"> 
        <div class="os1">
            <span class="os1-1"></span>
            <span class="ts1-2">Our Team</span>
            <span class="os1-1"></span>
        </div>
        <div id="carouselExampleCaptions" class="carousel slide mt-4" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <span class="os1-1 me-3"></span>
                <span  data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="caSlider active" aria-current="true" aria-label="Slide 1"></span>
                <span type="button" data-bs-target="#carouselExampleCaptions" class="caSlider me-3" data-bs-slide-to="1" aria-label="Slide 2"></span>
                <span class="os1-1"></span>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="3000">
                <div class="row">
                    <div class="col-sm-3 timgDiv">
                        <img  src="assets/Gowtham.webp" class="img-fluid timg" >
                    </div>
                    <div class="col-sm-9 d-flex flex-column cg">
                        <span class="ca-t1-2-1">Gowdhaman Margabandu</span>
                        <span class="ca-t1-22">
                            <span class="ca-t1-2-2">Founder & CTO</span>
                            <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid ti" >
                            <span class="ca-t1-2-3">Bengaluru, KA, India</span>
                        </span>
                        <span class="ca-t1-2-4">Gowdhaman “Gowtham” Margabandu is a Founder & the CTO of FRI. He is an experienced IT professional in the Financial Services, Transportation, and Insurance industries.</span>
                        <span class="ca-t1-2-4">He has launched products in the EdTech, Aviation, and DeepTech Hospitality sectors. </span>
                        <span class="ca-t1-2-4">He has extensive engineering and executive experience at top global firms including IBM, PricewaterhouseCoopers, and TCS in Europe, America, and Asia.</span>
                    </div>
                </div>
              </div>
              <div class="carousel-item" data-bs-interval="3000">
                <div class="row">
                    <div class="col-sm-3 timgDiv">
                        <img  src="assets/Barry.webp" class="img-fluid timg" >
                    </div>
                    <div class="col-sm-9 d-flex flex-column cg">
                        <span class="ca-t1-2-1">Barry Adair</span>
                        <span class="ca-t1-22">
                            <span class="ca-t1-2-2">COO</span>
                            <img  src="assets/Dot-separator.webp" class="fd-3-2 img-fluid  ti" >
                            <span class="ca-t1-2-3">Atlanta, GA, USA</span>
                        </span>
                        <span class="ca-t1-2-4">Barry Adair is the Chief Operating Officer of FRI. He is an experienced professional in heavy engineering, global logistics, and premium marketing event production.</span>
                        <span class="ca-t1-2-4">Before joining FRI, he was a project manager at Czarnowski delivering bespoke, experiential environments for premier brands in the automotive industry. </span>
                        <span class="ca-t1-2-4">Earlier in his career, Barry was a mechanical designer and resident engineer at CH2M (Jacobs Solutions) on global EPC projects in the power generation sector.</span>
                    </div>
                </div>
              </div>
            </div>
        </div>
    </div> -->
    <!-- Compatible with -->
    <div class="compatibleWith">
        <div class="os1">
            <span class="os1-1"></span>
            <span class="os1-2">Compatible with</span>
            <span class="os1-1"></span>
        </div>
        <!-- <p class="text-center compatibleWith">Compatible with</p> -->
        <div class="card h-100 pt-5 pb-2 border-0">
            <div class="row">
                <div class="col-sm-5">
                    <div class="card-body">
                        <p class="card-title rethink21">Multi Device Compatibility</p>
                        <p class="card-title compatibleWith1">We Support</p>
                        <p class="compatibleWith2 card-text">iPads running iOS version 13 and later and tablets running Android version 6 and later with 130MB of available storage.</p>
                        <div>
                            <img  src="assets/Frame72.webp" class="appicon img-fluid" >
                            <img  src="assets/Frame73.webp" class="appicon img-fluid" >
                        </div>
                    </div>
                </div>
                <div class="col-sm-7">
                    <img  src="assets/Group1000002406.webp" class="img-fluid" >
                </div>
            </div>
        </div>
    </div>
    <!-- Footer -->
    <div class="FooterCls"> <!--container  mb-5-->
        <div class="row cr">
            <div class="col-sm-6 f1">
                <img  src="assets/Footer_Logo.webp" class="f1-1 img-fluid" >
                <div class="f11">
                    <a href="https://instagram.com/friglobal/" target="_blank"> <img  src="assets/bxl_instagram-alt.webp" class="f1-2 img-fluid" > </a>
                    <a href="https://facebook.com/friglobal" target="_blank"> <img  src="assets/akar-icons_facebook-fill.webp" class="f1-2 img-fluid" > </a>
                    <a href="https://twitter.com/friglobal" target="_blank"> <img  src="assets/akar-icons_twitter-fill.webp" class="f1-2-t img-fluid" > </a>
                    <a href="https://linkedin.com/company/frisome/" target="_blank"> <img  src="assets/akar-icons_linkedin-fill.webp" class="f1-2 img-fluid" > </a>
                    <a href="https://youtube.com/channel/UCXI_qP-pFm171kamLFN_rdg" target="_blank"> <img  src="assets/akar-icons_youtube-fill.webp" class="f1-2 img-fluid" > </a>
                </div>
                <p class="f1-3 mt-3">© 2024 Frisome.com All rights reserved</p>
            </div>
            <div class="col-sm-6">
                <div class="row cr">
                    <div class="col-sm-7 f21">
                        <p class="f21-1">Contact Legal Info</p>
                        <p class="f21-2">867 Myrtle Street <br>
                            Atlanta, GA 30308, <br>
                            United States of America</p>
                        <hr class="f21-3">
                        <p class="f21-2">www.frisome.com</p>
                    </div>
                    <div class="col-sm-5">
                        <p class="f21-1">Quick Links</p>
                        <p class="f21-2"> <a  (click)="termsnav()">Terms of Service</a> </p>
                        <p class="f21-2"> <a  (click)="legalnav()">Legal</a> </p>
                        <p class="f21-2"> <a  (click)="contactnav()">Contact US</a> </p>
                        <p class="f21-2"> <a  (click)="homenav()">Home</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>