import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
  { path: 'terms', loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule) }, 
  { path: 'legal', loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule) }, 
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) }, 
  { path: 'demo', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) }, 
  { path: 'errorp', loadChildren: () => import('./errorp/errorp.module').then(m => m.ErrorpModule) },
  //{ path: '**', loadChildren: () => import('./errorp/errorp.module').then(m => m.ErrorpModule) },
  // { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }, 
  { path: '', component:HomeComponent },
  { path: '**', redirectTo: ''  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
